import React, { useEffect, useState } from 'react'
import BasePage from '../../../components/basePage'
import {
	Button,
	Text,
	Flex,
	Container,
	useToast,
	Box,
	Accordion,
	AccordionButton,
	AccordionItem,
	AccordionPanel,
	AccordionIcon
} from '@chakra-ui/react'
import { Link, navigate, useStaticQuery, graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import QRCode from 'react-qr-code'
import Layout from '../../../layout'
import { useQuery } from '@tanstack/react-query'
import { getGroupBy7RId, getMyGroups } from '../../../services/group'
import Spinner from '../../../components/spinner'

function generateQRValue(data) {
	// Encode the object as a JSON string
	const jsonEncodedData = JSON.stringify(data)

	// Convert the JSON string to UTF-8 bytes
	const utf8EncodedData = new TextEncoder().encode(jsonEncodedData)

	// Encode the UTF-8 bytes as a Base64 string
	const base64EncodedData = btoa(
		String.fromCharCode.apply(null, utf8EncodedData)
	)

	return base64EncodedData
}

const BookingOverview = ({ bookingId }) => {
	const { isLoading, isError, data } = useQuery({
		queryKey: ['booking', bookingId],
		queryFn: () => getGroupBy7RId(bookingId),
		retry: false
	})

	const title = 'Booking Details'

	if (isLoading) {
		return (
			<Layout title={title}>
				<Flex
					alignItems={'center'}
					justifyContent={'center'}
					height="100vh"
				>
					<Spinner />
				</Flex>
			</Layout>
		)
	}

	if (isError || (isLoading && !data)) {
		return (
			<Layout title={title}>
				<Flex
					alignItems={'center'}
					justifyContent={'center'}
					height="100vh"
				>
					<BasePage
						theme="dark"
						fullWidthActionBtns
						largeTitle={
							<StaticImage
								width={380}
								alt="Logo"
								style={{ marginBottom: 24, width: '200px' }}
								src="../../images/logo-white.png"
							/>
						}
					>
						<Container
							maxW="md"
							color={'white'}
							textAlign={'center'}
						>
							<Text
								variant="lgg"
								color="white"
								upper
								textAlign={'center'}
								mb="2"
								mt="10"
							>
								NO BOOKING FOUND
							</Text>
							<Text
								variant="m"
								color="white"
								textAlign={'center'}
								mb="2"
							>
								Please make sure you have the correct
								reservation id
							</Text>
						</Container>
					</BasePage>
				</Flex>
			</Layout>
		)
	}

	return (
		<Layout title={title}>
			<Flex
				alignItems={'center'}
				justifyContent={'center'}
				height="100vh"
			>
				<BasePage
					theme="dark"
					fullWidthActionBtns
					largeTitle={
						<StaticImage
							width={380}
							alt="Logo"
							style={{ marginBottom: 24, width: '200px' }}
							src="../../images/logo-white.png"
						/>
					}
				>
					<Container
						maxW="md"
						color={'white'}
						textAlign={'center'}
						display={'flex'}
						justifyContent={'center'}
						alignItems={'center'}
						flexDir={'column'}
					>
						<Text
							variant="m"
							color="white"
							textAlign={'center'}
							mb="4"
						>
							The games are ready. The booking set:
						</Text>

						<Text
							variant={'lgg'}
							color="white"
							textAlign={'center'}
						>
							{data.eventDate}
						</Text>
						<Text
							variant="ml"
							mb="10"
							color="white"
							textAlign={'center'}
						>
							{data.eventTime}
						</Text>

						<QRCode
							value={generateQRValue({
								id: bookingId,
								ts: data.eventDate
							})}
						/>
					</Container>
				</BasePage>
			</Flex>
		</Layout>
	)
}

export default BookingOverview
